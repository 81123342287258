import { useCallback } from 'react';

import { useNotifications } from '@personly/libs-providers';

const useEmailAddresses = () => {
  const { createNotification } = useNotifications();

  const verifyEmailAddress = useCallback(
    async (token, cb) => {
      const pathname = '/agon/verifyEmailAddress';

      const url = new URL(pathname, process.env.NEXT_PUBLIC_SELF_URL);
      url.searchParams.set('token', token);

      try {
        const response = await fetch(url);
        const { result } = await response.json();

        cb(result);
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      }
    },
    [createNotification]
  );

  return { verifyEmailAddress };
};

export default useEmailAddresses;
