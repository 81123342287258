import { useCallback, useReducer } from 'react';

import { useNotifications } from '@personly/libs-providers';

const initialState = {
  personalCity: {
    name: '',
    id: '',
    provinceName: '',
    countryName: '',
    location: null,
  },
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PERSONAL_CITIES_FETCHED': {
      return { ...state, data: action.data };
    }

    case 'PERSONAL_CITIES_CLEARED': {
      return { ...state, data: [] };
    }

    case 'PERSONAL_CITY_FETCHED': {
      return { ...state, personalCity: { ...action.data } };
    }

    case 'PERSONAL_CITY_CLEARED': {
      return {
        ...state,
        personalCity: { ...initialState.personalCity },
      };
    }

    case 'PERSONAL_PERMISSIONS_FETCHED': {
      return { ...state, personalPermissions: [...action.data] };
    }

    default:
      return state;
  }
};

const usePersonals = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { createNotification } = useNotifications();

  const searchPersonalCities = useCallback(
    async (search, languageHandle) => {
      const pathname = '/agon/searchPersonalCities';

      const url = new URL(pathname, process.env.NEXT_PUBLIC_SELF_URL);
      url.searchParams.set('search', search);
      url.searchParams.set('languageHandle', languageHandle);

      try {
        const response = await fetch(url);
        const { result } = await response.json();

        dispatch({ type: 'PERSONAL_CITIES_FETCHED', data: result });
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      }
    },
    [createNotification]
  );

  const clearPersonalCities = useCallback(() => {
    dispatch({ type: 'PERSONAL_CITIES_CLEARED' });
  }, []);

  const getPersonalCity = useCallback(
    async (id) => {
      const pathname = '/agon/getPersonalCity';

      const url = new URL(pathname, process.env.NEXT_PUBLIC_SELF_URL);
      url.searchParams.set('id', id);

      try {
        const response = await fetch(url);
        const { result } = await response.json();

        dispatch({ type: 'PERSONAL_CITY_FETCHED', data: result });
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      }
    },
    [createNotification]
  );

  const clearPersonalCity = useCallback(() => {
    dispatch({ type: 'PERSONAL_CITY_CLEARED' });
  }, []);

  return {
    personalCity: state.personalCity,
    personalCities: state.data,
    getPersonalCity,
    clearPersonalCity,
    clearPersonalCities,
    searchPersonalCities,
  };
};

export default usePersonals;
